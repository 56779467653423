<template>
  <el-container>
    <el-header style="display: flex; align-items: center">
      <div style="display: flex; justify-content: flex-start; cursor: default">
        <span @click="$router.push('/admin/videorecord/lists')">全部考试 > </span
        ><span @click="$router.push('/admin/videorecord/examlist')">{{ examName }} > </span
      ><span @click="$router.push('/admin/videorecord/monitorgroup')" style="color: #563279">{{ subjectName }} ></span>
        <span @click="$router.push('/admin/videorecord/nameAndZkzNum')" style="color: #563279">{{ groupName }} ></span>
        <span style="color: #563279" @click="$router.back()">{{ studentName }} & {{ zkzNum }} ></span>
        <span style="color: #563279">{{ typeName }}</span>
      </div>
    </el-header>
    <el-main style="padding-top: 5px">
      <div
          style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
        "
      >
        <div
            style="color: #333333; font-size: 20px; font-weight: bold; flex: 1"
        >
          {{ typeName }}
        </div>
        <div style="flex: 2"></div>
      </div>
      <el-table
          :data="videoFrontList"
          stripe
          style="width: 100%; margin-top: 10px; overflow: auto"
      >
        <el-table-column
            label="全选"
            type="selection"
            width="55"
            align="center"
        >
        </el-table-column>
        <el-table-column label="视频地址">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.videoUrl }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
import {getVideoBackList} from "r/admin/videorecord";

export default {
  data() {
    return {
      videoFrontList: [],
      total: 0,
      examName: "",
      subjectName: "",
      subjectUuid: "",
      groupName: "",
      zkzNum: "",
      studentName: "",
      typeName: ""
    };
  },
  created() {
    this.examName = window.localStorage.getItem("examName");
    this.subjectName = window.localStorage.getItem("subjectName");
    this.subjectUuid = window.localStorage.getItem("subjectUuid");
    this.groupName = window.localStorage.getItem("groupName");
    this.zkzNum = window.localStorage.getItem("zkzNum");
    this.studentName = window.localStorage.getItem("studentName");
    this.typeName = window.localStorage.getItem("typeName");
    this.getVideoList();
  },
  methods: {
    async getVideoList() {
      const {data: res} = await getVideoBackList({
        zkzNum: this.zkzNum,
        subjectUuid: this.subjectUuid
      });
      if (res.code != 200) {
        this.$message({
          message: res.code,
          type: "error",
        });
      }
      if (res.code == 200) {
        res.data.forEach(item => {
          this.videoFrontList.push({
            "videoUrl": item
          })
        })
      }
    }
  },
};
</script>

<style scoped>
/* 去除表格的内边框 */
::v-deep .el-table__row > td {
  border: none;
}

/* 去除最下面的边框线 */
::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner:hover {
  border: none;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #563279;
  border-radius: 40px;
}

::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 40px;
}

::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #dcdfe6;
  border-color: #fff;
}

::v-deep .el-checkbox__inner {
  border: none;
  background: #dcdfe6;
}

::v-deep .el-input__inner {
  border: none;
  background: #fafafa;
}
</style>